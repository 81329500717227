@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Klee+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: "Klee One", cursive;
  height: 100vh;
  margin: 0;
  padding: 0;
  /* background-color: rgb(239, 240, 241); */
  background-color: rgb(236, 245, 244);
  /* font-size: calc(1px + 2vmin); */
  /* font-size: calc(2.3vmin); */
  /* min-width: 1000px; */
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.TableComponent {
    height: 90%;
    display: flex;
    width: 70%;
    font-size: 18px;
    text-align: center;
    min-width: 1200px;
    margin: 15px auto;

    background-color: rgb(236, 245, 244);
}
.InnerBox {
    width: 90%;
    margin: 10px auto;
    height: 90%;
    background-color: white;
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}

.ProgressInput {
    /* height: 350px; */
    display: flex;
    min-height: 320px;
    width: 70%;
    font-size: 18px;
    text-align: center;
    min-width: 1200px;
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.InnerBox {
    /* display: inline-block; */
    width: 90%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
    /* border-radius: 15px; */
}
.TableBox {
    width: 85%;
    /* height: 32vh; */
    height: 270px;
    margin: auto auto;
    display: flex;
    justify-content: start;
    text-align: start;
}
.InputTable {
    margin: 5px 15px;
}
.InputItem {
    display: flex;
    justify-content: start;
    text-align: start;
    align-items: center;
    margin-top: 5px;
}
.InputItem div {
    margin-right: 20px;
}
.mr-0 {
    margin-right: 0;
}

.v-line {
    border-left: thick solid rgb(38, 43, 60);
    /* height: 50px; */
    height: 25px;
    /* width: 10px; */
    margin-right: 10px;
}
.come {
    color: red;
    margin-left: 5px;
}

.HeaderComponent {
    font-family: "Klee One", cursive;
    height: 90px;
    display: flex;
    width: 70%;
    text-align: center;
    min-width: 1200px;
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.InnerHeader {
    width: 90%;
    margin: auto auto;

    background-color: rgb(236, 245, 244);
    justify-content: center;
}

body {
    background-color: rgb(236, 245, 244);
}

.my-text-size {
    /* font-size: calc(1px+3vmin); */
    font-size: calc(10px+3vmin);
}

.my-text-size-big {
    font-size: calc(1px+5vmin);
}

.row-margin-10 {
    margin: 0 10px;
}
/* .ProgressPage {
  display: flex;
  flex-direction: column;
} */
.my-footer {
    background-color: rgb(236, 245, 244);
    height: 100%;
    /* height: 110px; */
    width: 70%;

    min-width: 1200px;
    margin: auto auto;
}

.MasterComponent {
    /* height: 350px; */
    display: flex;
    min-height: 420px;
    width: 70%;
    font-size: 18px;
    text-align: center;
    min-width: 1200px;
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.mInnerBox {
    /* display: inline-block; */
    width: 90%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    height: 370px;
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
    /* border-radius: 15px; */
}
.mTableBox {
    width: 85%;
    /* height: 32vh; */
    height: 350px;
    margin: auto auto;
    display: flex;
    justify-content: start;
    text-align: start;
}
.mInputTable {
    margin: 10px 15px;
}
.mInputItem {
    display: flex;
    justify-content: start;
    text-align: start;
    align-items: center;
    margin-top: 15px;
}
.mInputItem div {
    margin-right: 20px;
}
.v-line {
    border-left: thick solid rgb(38, 43, 60);
    /* height: 50px; */
    height: 25px;
    /* width: 10px; */
    margin-right: 10px;
}
.comeA {
    color: red;
    margin-left: 5px;
}

.LTable {
    background-color: rgb(236, 245, 244);
    /* background-color: white; */
    min-width: 800px;
    height: 100vh;
    display: flex;
}
.Inner-Table {
    /* width: 700px; */
    background-color: white;
    display: flex;
    /* height: 600px; */
    flex-direction: column;
    margin: auto auto;
    /* margin-top: 150px; */
    width: 550px;
    /* border-radius: 12px; */
    box-shadow: 0 0.1px 1px 0.3px gray;
}
.Content-Table {
    margin-top: 150px;
    background-color: white;
    display: flex;
    height: 500px;
    flex-direction: column;
    margin: 30px auto;

    width: 500px;
}
.LItem {
    display: flex;
    height: 70px;
    /* margin: 0 auto; */
    /* margin: auto auto; */
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-top: 15px;
}
.LItem-Title {
    margin-bottom: 50px;
}
.LItem-Iput > div {
    width: 400px;
    margin-right: auto;
    margin-left: auto;
}
.LItem-Iput > button {
    margin-top: 80px;
    width: 400px;
}

.CustomerHeaderComponent {
    font-family: "Klee One", cursive;
    height: 90px;
    display: flex;

    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.CustomerInnerHeader {
    /* display: inline-block; */
    width: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
 flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
    /* border-radius: 15px; */
}

html {
    font-size: 16px;
}
.CustomerInput {
    font-family: "Klee One", cursive;
    height: 200px;
    display: flex;

    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.CustomerInnerBox {
    width: 95%;
    height: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}
.InputInner {
    width: 85%;
    height: 85%;
    margin: auto auto;
    background-color: white;
    /* display: flex; */
    /* flex-direction: column; */
}
.InputInner > div {
    display: flex;
    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
}
.ProgressSearch {
    display: flex;
    width: 90%;
    height: 90%;

    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
}
.InputItem {
    font-size: 1rem;
    white-space: nowrap;
}
@media screen and (max-width: 510px) {
    html {
        font-size: 12px;
    }
    .CustomerInput {
        height: 150px;
    }
}

html {
    font-size: 16px;
}
.CustomerTable {
    font-family: "Klee One", cursive;
    height: 600px;
    display: flex;
    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.CustomerInnerTable {
    width: 95%;
    height: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}
.InnerTable {
    width: 85%;
    height: 85%;
    margin: auto auto;
    background-color: white;
    /* display: flex; */
    /* flex-direction: column; */
}
.InnerTable > div {
    display: flex;
    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
    /* margin-top: 5px; */
}
/* .ProgressSearch {
    display: flex;
    width: 90%;
    height: 90%;

    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
} */
.InputItem {
    font-size: 1rem;
    white-space: nowrap;
}
.ContentDiv {
    margin: 15px 10px 30px 10px;
}
.v-line {
    border-left: thick solid rgb(38, 43, 60);
    /* height: 50px; */
    height: 25px;
    /* width: 10px; */
    margin-right: 10px;
}
@media screen and (max-width: 510px) {
    html {
        font-size: 10px;
    }
    .CustomerTable {
        height: 470px;
    }
}

html {
    font-size: 16px;
}
.CustomerExplain {
    font-family: "Klee One", cursive;
    height: 330px;
    display: flex;

    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.ExplainInnerBox {
    width: 95%;
    height: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}
.ExplainInputInner {
    width: 100%;
    height: 85%;
    margin: auto auto;
    background-color: white;
    /* display: flex; */
    /* flex-direction: column; */
}
.ExplainInputInner > div {
    display: flex;
    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
}
.ProgressSearch {
    display: flex;
    width: 90%;
    height: 90%;

    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
}
/* .ExplainInputItem {
    display: block;
    font-size: 0.8rem;
    white-space: nowrap;
} */
.ExplainInputItem {
    /* width: 90%; */
    margin: auto;
    /* display: block; */
    font-size: 0.8rem;
    white-space: normal;
    word-break: break-all;
    /* overflow: auto; */
}
@media screen and (max-width: 510px) {
    html {
        font-size: 12px;
    }
    .CustomerInput {
        height: 150px;
    }
}

.ErrorPage404 {
    background-color: rgb(236, 245, 244);
    min-width: 1200px;
    display: flex;
    height: 100vh;
}
.inner-content {
    width: 50%;

    margin: 100px auto;

    display: flex;
    flex-direction: row;
}
.picture-content {
    width: 50%;
    margin: 100px auto;
}
.text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 100px auto;
}
.myLink {
    text-decoration: none;
    font-size: 30px;
    font-weight: 900;
}
.myLink:link {
    color: rgb(82, 149, 180);
}
.myLink:visited {
    color: rgb(82, 149, 180);
}

