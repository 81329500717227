.TableComponent {
    height: 90%;
    display: flex;
    width: 70%;
    font-size: 18px;
    text-align: center;
    min-width: 1200px;
    margin: 15px auto;

    background-color: rgb(236, 245, 244);
}
.InnerBox {
    width: 90%;
    margin: 10px auto;
    height: 90%;
    background-color: white;
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}
