.ErrorPage404 {
    background-color: rgb(236, 245, 244);
    min-width: 1200px;
    display: flex;
    height: 100vh;
}
.inner-content {
    width: 50%;

    margin: 100px auto;

    display: flex;
    flex-direction: row;
}
.picture-content {
    width: 50%;
    margin: 100px auto;
}
.text-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 100px auto;
}
.myLink {
    text-decoration: none;
    font-size: 30px;
    font-weight: 900;
}
.myLink:link {
    color: rgb(82, 149, 180);
}
.myLink:visited {
    color: rgb(82, 149, 180);
}
