@import url("https://fonts.googleapis.com/css2?family=Klee+One&display=swap");
.CustomerHeaderComponent {
    font-family: "Klee One", cursive;
    height: 90px;
    display: flex;

    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.CustomerInnerHeader {
    /* display: inline-block; */
    width: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
 flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
    /* border-radius: 15px; */
}
