@import url("https://fonts.googleapis.com/css2?family=Klee+One&display=swap");
.HeaderComponent {
    font-family: "Klee One", cursive;
    height: 90px;
    display: flex;
    width: 70%;
    text-align: center;
    min-width: 1200px;
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.InnerHeader {
    width: 90%;
    margin: auto auto;

    background-color: rgb(236, 245, 244);
    justify-content: center;
}
