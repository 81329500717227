.MasterComponent {
    /* height: 350px; */
    display: flex;
    min-height: 420px;
    width: 70%;
    font-size: 18px;
    text-align: center;
    min-width: 1200px;
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.mInnerBox {
    /* display: inline-block; */
    width: 90%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    height: 370px;
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
    /* border-radius: 15px; */
}
.mTableBox {
    width: 85%;
    /* height: 32vh; */
    height: 350px;
    margin: auto auto;
    display: flex;
    justify-content: start;
    text-align: start;
}
.mInputTable {
    margin: 10px 15px;
}
.mInputItem {
    display: flex;
    justify-content: start;
    text-align: start;
    align-items: center;
    margin-top: 15px;
}
.mInputItem div {
    margin-right: 20px;
}
.v-line {
    border-left: thick solid rgb(38, 43, 60);
    /* height: 50px; */
    height: 25px;
    /* width: 10px; */
    margin-right: 10px;
}
.comeA {
    color: red;
    margin-left: 5px;
}
