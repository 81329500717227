@import url("https://fonts.googleapis.com/css2?family=Klee+One&display=swap");
html {
    font-size: 16px;
}
.CustomerExplain {
    font-family: "Klee One", cursive;
    height: 330px;
    display: flex;

    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.ExplainInnerBox {
    width: 95%;
    height: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}
.ExplainInputInner {
    width: 100%;
    height: 85%;
    margin: auto auto;
    background-color: white;
    /* display: flex; */
    /* flex-direction: column; */
}
.ExplainInputInner > div {
    display: flex;
    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
}
.ProgressSearch {
    display: flex;
    width: 90%;
    height: 90%;

    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
}
/* .ExplainInputItem {
    display: block;
    font-size: 0.8rem;
    white-space: nowrap;
} */
.ExplainInputItem {
    /* width: 90%; */
    margin: auto;
    /* display: block; */
    font-size: 0.8rem;
    white-space: normal;
    word-break: break-all;
    /* overflow: auto; */
}
@media screen and (max-width: 510px) {
    html {
        font-size: 12px;
    }
    .CustomerInput {
        height: 150px;
    }
}
