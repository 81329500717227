.LTable {
    background-color: rgb(236, 245, 244);
    /* background-color: white; */
    min-width: 800px;
    height: 100vh;
    display: flex;
}
.Inner-Table {
    /* width: 700px; */
    background-color: white;
    display: flex;
    /* height: 600px; */
    flex-direction: column;
    margin: auto auto;
    /* margin-top: 150px; */
    width: 550px;
    /* border-radius: 12px; */
    box-shadow: 0 0.1px 1px 0.3px gray;
}
.Content-Table {
    margin-top: 150px;
    background-color: white;
    display: flex;
    height: 500px;
    flex-direction: column;
    margin: 30px auto;

    width: 500px;
}
.LItem {
    display: flex;
    height: 70px;
    /* margin: 0 auto; */
    /* margin: auto auto; */
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-top: 15px;
}
.LItem-Title {
    margin-bottom: 50px;
}
.LItem-Iput > div {
    width: 400px;
    margin-right: auto;
    margin-left: auto;
}
.LItem-Iput > button {
    margin-top: 80px;
    width: 400px;
}
