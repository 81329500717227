body {
    background-color: rgb(236, 245, 244);
}

.my-text-size {
    /* font-size: calc(1px+3vmin); */
    font-size: calc(10px+3vmin);
}

.my-text-size-big {
    font-size: calc(1px+5vmin);
}

.row-margin-10 {
    margin: 0 10px;
}
/* .ProgressPage {
  display: flex;
  flex-direction: column;
} */
.my-footer {
    background-color: rgb(236, 245, 244);
    height: 100%;
    /* height: 110px; */
    width: 70%;

    min-width: 1200px;
    margin: auto auto;
}
