@import url("https://fonts.googleapis.com/css2?family=Klee+One&display=swap");
html {
    font-size: 16px;
}
.CustomerTable {
    font-family: "Klee One", cursive;
    height: 600px;
    display: flex;
    text-align: center;
    max-width: 800px;
    /* width: 70%; */
    margin: auto auto;
    background-color: rgb(236, 245, 244);
}
.CustomerInnerTable {
    width: 95%;
    height: 95%;
    margin: auto auto;
    background-color: white;
    /* height: 270px; */
    /* height: 7vh; */
    /* display: flex;
    flex-direction: row; */
    display: flex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);

    justify-content: center;
    color: rgb(38, 43, 60);
}
.InnerTable {
    width: 85%;
    height: 85%;
    margin: auto auto;
    background-color: white;
    /* display: flex; */
    /* flex-direction: column; */
}
.InnerTable > div {
    display: flex;
    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
    /* margin-top: 5px; */
}
/* .ProgressSearch {
    display: flex;
    width: 90%;
    height: 90%;

    font-size: 1rem;
    justify-content: start;
    text-align: start;
    align-items: center;
} */
.InputItem {
    font-size: 1rem;
    white-space: nowrap;
}
.ContentDiv {
    margin: 15px 10px 30px 10px;
}
.v-line {
    border-left: thick solid rgb(38, 43, 60);
    /* height: 50px; */
    height: 25px;
    /* width: 10px; */
    margin-right: 10px;
}
@media screen and (max-width: 510px) {
    html {
        font-size: 10px;
    }
    .CustomerTable {
        height: 470px;
    }
}
